.navbar-logs text {
    color: rgb(255, 255, 255);
    margin-right: 10px;
    font-weight: bolder;
    font-size: larger;
  }

  .navbar-logs{
    gap: 5px;
  }
  
  .logs-btn {
    font-size: smaller;
    font-size: 15px;
  }
  
  .main-div {
    overflow: scroll;
  }
  
  .table-logs {
    width: 100%;
    margin-top: 0px;
  }/*# sourceMappingURL=Logs.css.map */

  .navbar-logs{
    display: flex;
    margin: 2px;
  }

  .logs-text{
    margin: auto;
  }

  .date-picker-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .date-picker-container > * {
    margin: 0 5px;
  }
  
  .strategy {
    background-color: lightgreen;
  }


  /* Add this CSS to your stylesheet */
.modal {
  position: absolute;
  width: 200px;
  top: 70px;
  right: 0px;
  background-color: rgba(163, 165, 167, 0.836);
  z-index: 1000;
  border: 0.5px solid rgb(255, 255, 255);
  border-radius: 2%;
  cursor: pointer;
}

.modal button{
  margin: 5px;
}

.strategy-checkboxes{
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.date-range{
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.apply{
  margin: 5px;
}

.modal-h2{
  font-weight: bold;
  margin: 5px;
  justify-content: center;
}