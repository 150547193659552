.parent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto; /* Change 0.05fr to auto for better mobile experience */
  grid-column-gap: 0px;
  grid-row-gap: 5px;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
}

.div1 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 5px;
  grid-area: 1 / 1 / 2 / 6;
  background-color: rgba(6, 1, 71, 0.71);
}

.active{
  background-color:aquamarine;
}

.div2 {
  grid-area: 2 / 1 / 3 / 2;
  background-color: rgb(43, 42, 39);
  overflow: scroll;
}

.table-screen-two thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.div3 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 5px;
  grid-area: 3 / 1 / 4 / 2;
  background-color: rgb(0, 0, 0);
}

.toggle-input {
  display: none;
}

.toggle-label {
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: rgb(23, 175, 0);
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  color: white;
}

.toggle-label:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-input:checked + .toggle-label {
  background-color: rgb(190, 12, 12);
}

.toggle-input:checked + .toggle-label:before {
  transform: translateX(30px);
}

table {
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid rgba(161, 160, 160, 0.731);
  padding: 2px;
  font-family: "Allerta Stencil";
  color: azure;
  background-color: rgba(240, 255, 255, 0.1);
  font-size: 18px;
  text-align: center;
}

table th {
  background-color: black;
  color: rgb(209, 247, 247);
}

table tr:hover td {
  color: rgb(3, 250, 20);
  background-color: rgba(192, 192, 192, 0.401);
}

.usa-time {
  font-weight: bold;
  font-family: "Allerta Stencil";
  font-size: 26px;
  color: rgb(99, 255, 47);
  margin-left: 5px;
  z-index: 100;
  background-color: rgba(240, 255, 255, 0);
  border: none;
  pointer-events: none;
}

div.usa-time text {
  margin-left: 2%;
}

div.usa-time .indices {
  float: right;
  font-weight: normal;
  margin-top: auto;
  margin-bottom: auto;
}

.login {
  margin: 5px 0px 5px 0px;
  float: right;
}

.footer-btn {
  margin: 5px 0px 5px 0px;
  float: right;
}

.changed_up {
  transition: 0.5;
  /* background-color:rgb(4, 245, 68); */
  -webkit-animation: move_up 0.6s;
          animation: move_up 0.6s;
}

.changed_down {
  transition: 0.5;
  /* background-color:red; */
  -webkit-animation: move_down 0.6s;
          animation: move_down 0.6s;
}

@-webkit-keyframes move_up {
  from {
    background-color: none;
  }
  to {
    background-color: rgba(2, 253, 27, 0.648);
  }
}

@keyframes move_up {
  from {
    background-color: none;
  }
  to {
    background-color: rgba(2, 253, 27, 0.648);
  }
}
@-webkit-keyframes move_down {
  from {
    background-color: none;
  }
  to {
    background-color: rgba(255, 28, 28, 0.747);
  }
}
@keyframes move_down {
  from {
    background-color: none;
  }
  to {
    background-color: rgba(255, 28, 28, 0.747);
  }
}
.sorted-asc,
.sorted-desc {
  background-color: lightgreen;
  color: black;
}

.sorted-asc::after {
  content: "\25bc";
  padding-left: 0.1em;
  font-size: 0.8em;
}

.sorted-desc::after {
  content: "\25b2";
  padding-left: 0.1em;
  font-size: 0.8em;
}

th {
  cursor: pointer;
}

.criteria-invalid {
  background-color: rgba(255, 81, 81, 0.539);
}

.criteria-met {
  background-color: rgba(16, 216, 19, 0.631);
}

.trade-prepare {
  background-color: rgba(255, 203, 46, 0.555);
}

.trade-invalid td {
  background-color: rgba(255, 27, 236, 0.851);
  animation: blinker 2s linear infinite;
  z-index: 0;
}

.trade-short {
  background-color: rgba(255, 81, 81, 0.539);
}

.trade-win {
  background-color: rgba(34, 255, 9, 0.539);
}

.green-dig {
  color: rgb(0, 255, 0);
  font-weight: bold;
}

.red-dig {
  color: rgb(255, 19, 19);
  font-weight: bold;
}

.yellow-dig {
  color: rgb(243, 255, 19);
  font-weight: bold;
}

.white-dig {
  color: grey;
  font-weight: bold;
}

button {
  cursor: pointer;
  font-family: "Allerta Stencil";
  font-size: 20px;
}

button:hover {
  background-color: rgb(166, 166, 166);
}

/* Media Queries */
@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
  .div1 {
    flex-direction: column;
  }

  .div2 {
    grid-area: auto; /* Remove grid positioning for better mobile experience */
    overflow: scroll;
  }

  .div3 {
    flex-direction: column;
  }

  .toggle-label {
    width: 100px; /* Increase width for better touch target */
  }

  table th,
  table td {
    font-size: 16px; /* Reduce font size for smaller screens */
  }

  .usa-time {
    font-size: 20px; /* Reduce font size for smaller screens */
  }
}


.index_container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  justify-content: center; /* Centers items vertically */
  text-align: center; /* Centers text within the container */
}

.index{
  color: rgb(45, 202, 45);
  font-size: x-large;
  margin-left: 10px;
}